@import 'include/variables.less';

.main-top-slider {
	overflow-x: hidden;
	margin-bottom: 30px;
	@media (max-width: @screen-xs-max) {
    	height: 310px;
    }
    @media (min-width: @screen-sm-min) {
    	height: 380px;
    }
    @media (min-width: @screen-md-min) {
    	height: 400px;
    }
    @media (min-width: @screen-lg-min) {
    	height: 490px;
    }
    .slick-prev,
    .slick-next {
	    width: 40px;
	    height: 40px;
	    z-index: 1;
	    background-image: url('../img/arrows.svg');
	    background-size: auto 100%;
	    &:before {
	    	display: none;
	    }
    }
    .slick-prev {
    	background-position: 0 0;
    	left: 15px;
    }
    .slick-next {
    	background-position: -41px 0;
    	right: 15px;
    }
}

.main-top-slider__item {
	display: block;
	text-align: center;
	position: relative;
	outline: 0;
	padding: 0 20px;
	@media (max-width: @screen-xs-max) {
    	height: 310px;
    }
    @media (min-width: @screen-sm-min) {
    	height: 380px;
    }
    @media (min-width: @screen-md-min) {
    	height: 400px;
    }
    @media (min-width: @screen-lg-min) {
    	height: 490px;
    }
    img {
    	max-height: 100%;
    }
    &__text {
		background: @yellow;
		padding: 5px 15px;
		display: inline-block;
		&__title {
			.text-b;
			font-size: 26px;
			line-height: 1;
			margin: 0;
			&:first-letter {
				text-transform: uppercase;
			}
		}
		&__desc {
			.text-16;
			.text-i;
			color: #000;
			text-decoration: underline;
			text-transform: lowercase;
		}
	}
    &__text-wrapper {
		position: absolute;
	    color: #000;
	    width: 100%;
	    height: 100px;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    margin: auto;
	}
    &__price-wrapper {
		display: inline-block;
		background: @yellow;
		font-size: 19px;
		line-height: 1;
		padding: 5px 10px;
		margin-top: -1px;
	}
}

.price--main-slider {
	font-size: 28px;
	.text-nb;
	&:after {
		content: '-';
	}
}

.main-button-outer-wrapper {
	margin-bottom: 30px;
	background: @beige;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	&:before, &:after {
		content: ' ';
	    display: block;
	}
}

.main-button-wrapper {
    @media (max-width: @screen-xs-max) {
		width: 50%;
	}
}

.main-button {
	display: block;
	text-align: center;
	padding: 30px 0;
	height: 243px;
	&:hover {
		.main-button__text {
			text-decoration: underline;
		}
	}
	&--measure {
		.main-button__img {
			background-position: 0 0;
		}
	}
	&--calc {
		.main-button__img {
			background-position: -88px 0;
		}
	}
	&--question {
		.main-button__img {
			background-position: -176px 0;
		}
	}
	&--callback {
		.main-button__img {
			background-position: -265px 0;
		}
	}
	&__img {
		display: inline-block;
		width: 88px;
		height: 106px;
		background-position-y: bottom;
		background-image: url('../img/main/buttons_sprite.png');
		background-clip: content-box;
		margin-bottom: 5px;
	}
	&__text {
		width: 75px;
		display: inline-block;
		text-decoration: none;
		color: @black;
		.text-16;
		.text-nb;
	}
}

.main-quote {
	padding: 65px 0 30px;
	margin: 0;
	text-align: center;
	border-left: 0;
	max-width: 900px;
	margin: 0 auto;
	&__text {
		margin-bottom: 20px;
	}
	&__author {
		.text-14;
		.text-i;
		color: @grey;
	}
}


.video-table {
	@media (min-width: @screen-sm-min) {
		display: flex;
	    align-items: stretch;
	    margin-bottom: 30px;
	    &__cell {
	    	display: flex;
		    align-items: stretch;
		    float: none;
	    }
	}
	&__cell {
		margin-bottom: 15px;
	}
}

.main-video-container {
	position: relative;
	margin-bottom: 30px;
	padding: 50px 0;
	background-color: @beige;
	video {
		max-width: 100%;
		height: auto;
	}
}

.main-video-wrapper {
	width: 100%;
	@media (max-width: @screen-xs-max) {
		margin-bottom: 20px;
	}
}

#main-video {
	width: 100%;

}

.main-video-desc {
	@media (max-width: @screen-xs-max) {
		padding-left: 0;
	}
	@media (min-width: @screen-sm-min) {
		padding-left: 0;
	}
	@media (min-width: @screen-md-min) {
		padding-left: 45px;
	}
	h3 {
		color: @black;
		@media (min-width: @screen-md-min) {
			font-size: 45px;
		}
		@media (min-width: @screen-sm-min) {
			font-size: 35px;
		}
		@media (max-width: @screen-xs-max) {
			font-size: 22px;
		}
	}
}

.main-video-form-wrapper {
	@media (max-width: @screen-xs-max) {
		padding-left: 0;
	}
	@media (min-width: @screen-sm-min) {
		padding-left: 0;
	}
	@media (min-width: @screen-md-min) {
		padding-left: 45px;
	}
	input {
		border: none;
		width: 100%;
		@media (max-width: @screen-xs-max) {
			margin-bottom: 10px;
		}
	}
	.btn {
	    padding: 12px 30px;
	    margin-bottom: 0;
	    @media (max-width: @screen-xs-max) {
			width: 100%;
		}
	}
}

.main__faq {
	display: block;
	background: url('../img/main/main__faq.png') @green 50% bottom no-repeat;
	text-decoration: none;
	color: #fff;
	padding-top: 35px;
	padding-left: 15%;
	padding-right: 15%;
	padding-bottom: 35px;
	&:hover,
	&:active,
	&:focus,
	&:active:focus {
		text-decoration: none;
		color: #fff;
	}
	@media (max-width: @screen-xs-max) {
		padding-bottom: 200px;
	}
	@media (min-width: @screen-sm-min) {
		background-size: contain;
	}
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		background: @green;
	}
}

.main__faq__header {
	font-size: 80px;
	line-height: 1;
	.text-nb;
	margin-bottom: 15px;
	text-align: center;
	text-transform: uppercase;
}

.main__faq__text {
	.text-16;
	.text-nb;
	text-align: center;
}

.go-to-reviews {
	display: none;
}

.rate-capability {
	width: 100%;
	background: @beige;
	padding: 50px;
	.text-nb;
 	text-align: center;
	font-size: 23px;
	line-height: 0.95;
	color: @green;
}

.sheet {
	display: block;
	width: 100%;
	padding: 0px 25px;
	text-align: center;
	text-decoration: none;
}

.sheet--termookna {
	background-image: linear-gradient(#F4BE74 0%, #EF9559 100%);
}

.sheet__title {
	font-size: 22px;
	line-height: 1;
	.text-nb;
	color: #fff;
	&:hover, &:active, &:focus {
		text-decoration: none;
		color: #fff;
	}
}

.col-sm--flex {
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		display: flex !important;
	}
}

.col-lg--flex {
	@media (min-width: @screen-lg-min) {
		display: flex !important;
	}
}

.social {
	height: 245px;
	background-image: linear-gradient(#A3DAB8 0%, #87CC9D 100%);
	padding: 50px 20px;
	margin: -1px 0;
	text-align: center;
}

.social__title {
	font-size: 22px;
	line-height: 1;
	.text-nb;
	margin-bottom: 10px;
	color: #fff;
}

.social__preview {
	font-size: 16px;
	line-height: 1.22;
	.text-nb;
	color: @black;
	margin-bottom: 20px;
}

.social__link {
	display: inline-block;
	width: 30px;
	height: 30px;
	background-image: url('../img/main/soc_sprite.png');
	box-shadow: 0px 15px 30px 0px rgba(48,58,62,0.22);
	&-instagram {
		margin-right: 14px;
		background-position: 0 0;
	}
	&-facebook {
		background-position: -30px 0;
	}
}

.advantage-table {
	margin-bottom: 50px;
	@media (min-width: @screen-sm-min) {
		display: flex;
	    align-items: stretch;
		&__cell {
	    	display: flex;
		    align-items: stretch;
		    float: none;
	    }
	}
	&__cell {
		&:nth-child(4) {
			margin-bottom: 15px;
		    @media (max-width: @screen-md-max) {
		    	display: none;
		    }
	    }
	}
}

.btn-review-all,
.btn--banners {
	margin-bottom: 60px;
}

.advantage {
	border: 1px solid #e1e1e1;
	padding: 25px;
	text-align: center;
	-webkit-box-sizing: content-box;
	display: block;
	text-decoration: none;
	color: @black;
	&:hover,
	&:active,
	&:focus,
	&:active:focus {
		text-decoration: none;
		color: @black;
		border: 1px solid @yellow;
	}
	@media (max-width: @screen-xs-max) {
		padding: 25px;
	}
	@media (min-width: @screen-sm-min) {
		padding: 30px 25px;
	}
	@media (min-width: @screen-md-min) {
		padding: 40px;
	}
}

.advantage__img {
	height: 115px;
	display: flex;
	img {
		margin: auto;
	}
	@media (max-width: @screen-sm-max) {
		margin-bottom: 0;
	}
	@media (min-width: @screen-sm-min) {
		margin-bottom: 10px;
	}
}

.advantage__title {
	font-size: 22px;
    line-height: 1;
    .text-nb;
	color: @yellow;
	margin-bottom: 10px;
}

.advantage__text {
	margin-bottom: 15px;
	padding: 0 25px;
}

.advantage__text, .advantage__link {
	text-align: center;
	.text-16;
	.text-nb;
}

 .advantage__link {
 	color: @linkblue;
 	text-decoration: underline;
 	&:hover {
 		text-decoration: none;
 	}
 }

.news__header {
	margin-bottom: 20px;
}

.news__header__img {
	display: inline-block;
	vertical-align: baseline;
	margin-right: 15px;
}

.news__header__title {
	display: inline-block;
	margin-right: 25px;
	color: @black;
}

.news__header__links {
	display: inline-block;
	margin-bottom: 10px;
}

.news__header__link {
	font-size: 16px;
	line-height: 1.4;
	color: @darkgrey;
	text-decoration: none;
	&:not(:last-child) {
		&:after {
			content: ' / ';
		}
	}
	&.active {
		span {
			color: @darkgrey;
			text-decoration: none;
			&:hover, &:focus {
				cursor: default;
			}
		}
	}
	span {
		color: @linkblue;
		text-decoration: underline;
		&:hover, &:focus {
			text-decoration: none;
			cursor: pointer;
		}
	}
}

.banners__inner {
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		margin: 0;
	}
}

.banner {
	display: block;
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		padding: 0;
	}
	@media (max-width: @screen-xs-max) {
		margin-bottom: 15px;
	}
}

.btn--banners {
	margin-top: 30px;
}
