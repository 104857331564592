.text-16 {
  font-size: 16px;
  line-height: 1.375;
}
.text-14 {
  font-size: 14px;
  line-height: 1.286;
}
.text-s {
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}
.text-b {
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.text-i {
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: italic;
}
.text-n {
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: normal;
}
.text-nb {
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.center-text {
  text-align: center;
}
.right-text {
  text-align: right;
}
.main-top-slider {
  overflow-x: hidden;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .main-top-slider {
    height: 310px;
  }
}
@media (min-width: 768px) {
  .main-top-slider {
    height: 380px;
  }
}
@media (min-width: 992px) {
  .main-top-slider {
    height: 400px;
  }
}
@media (min-width: 1200px) {
  .main-top-slider {
    height: 490px;
  }
}
.main-top-slider .slick-prev,
.main-top-slider .slick-next {
  width: 40px;
  height: 40px;
  z-index: 1;
  background-image: url('../img/arrows.svg');
  background-size: auto 100%;
}
.main-top-slider .slick-prev:before,
.main-top-slider .slick-next:before {
  display: none;
}
.main-top-slider .slick-prev {
  background-position: 0 0;
  left: 15px;
}
.main-top-slider .slick-next {
  background-position: -41px 0;
  right: 15px;
}
.main-top-slider__item {
  display: block;
  text-align: center;
  position: relative;
  outline: 0;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .main-top-slider__item {
    height: 310px;
  }
}
@media (min-width: 768px) {
  .main-top-slider__item {
    height: 380px;
  }
}
@media (min-width: 992px) {
  .main-top-slider__item {
    height: 400px;
  }
}
@media (min-width: 1200px) {
  .main-top-slider__item {
    height: 490px;
  }
}
.main-top-slider__item img {
  max-height: 100%;
}
.main-top-slider__item__text {
  background: #f6cf45;
  padding: 5px 15px;
  display: inline-block;
}
.main-top-slider__item__text__title {
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 26px;
  line-height: 1;
  margin: 0;
}
.main-top-slider__item__text__title:first-letter {
  text-transform: uppercase;
}
.main-top-slider__item__text__desc {
  font-size: 16px;
  line-height: 1.375;
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: italic;
  color: #000;
  text-decoration: underline;
  text-transform: lowercase;
}
.main-top-slider__item__text-wrapper {
  position: absolute;
  color: #000;
  width: 100%;
  height: 100px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.main-top-slider__item__price-wrapper {
  display: inline-block;
  background: #f6cf45;
  font-size: 19px;
  line-height: 1;
  padding: 5px 10px;
  margin-top: -1px;
}
.price--main-slider {
  font-size: 28px;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.price--main-slider:after {
  content: '-';
}
.main-button-outer-wrapper {
  margin-bottom: 30px;
  background: #f5f4f0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.main-button-outer-wrapper:before,
.main-button-outer-wrapper:after {
  content: ' ';
  display: block;
}
@media (max-width: 767px) {
  .main-button-wrapper {
    width: 50%;
  }
}
.main-button {
  display: block;
  text-align: center;
  padding: 30px 0;
  height: 243px;
}
.main-button:hover .main-button__text {
  text-decoration: underline;
}
.main-button--measure .main-button__img {
  background-position: 0 0;
}
.main-button--calc .main-button__img {
  background-position: -88px 0;
}
.main-button--question .main-button__img {
  background-position: -176px 0;
}
.main-button--callback .main-button__img {
  background-position: -265px 0;
}
.main-button__img {
  display: inline-block;
  width: 88px;
  height: 106px;
  background-position-y: bottom;
  background-image: url('../img/main/buttons_sprite.png');
  background-clip: content-box;
  margin-bottom: 5px;
}
.main-button__text {
  width: 75px;
  display: inline-block;
  text-decoration: none;
  color: #32373b;
  font-size: 16px;
  line-height: 1.375;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.main-quote {
  padding: 65px 0 30px;
  margin: 0;
  text-align: center;
  border-left: 0;
  max-width: 900px;
  margin: 0 auto;
}
.main-quote__text {
  margin-bottom: 20px;
}
.main-quote__author {
  font-size: 14px;
  line-height: 1.286;
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: italic;
  color: #c3c3c3;
}
@media (min-width: 768px) {
  .video-table {
    display: flex;
    align-items: stretch;
    margin-bottom: 30px;
  }
  .video-table__cell {
    display: flex;
    align-items: stretch;
    float: none;
  }
}
.video-table__cell {
  margin-bottom: 15px;
}
.main-video-container {
  position: relative;
  margin-bottom: 30px;
  padding: 50px 0;
  background-color: #f5f4f0;
}
.main-video-container video {
  max-width: 100%;
  height: auto;
}
.main-video-wrapper {
  width: 100%;
}
@media (max-width: 767px) {
  .main-video-wrapper {
    margin-bottom: 20px;
  }
}
#main-video {
  width: 100%;
}
@media (max-width: 767px) {
  .main-video-desc {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .main-video-desc {
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .main-video-desc {
    padding-left: 45px;
  }
}
.main-video-desc h3 {
  color: #32373b;
}
@media (min-width: 992px) {
  .main-video-desc h3 {
    font-size: 45px;
  }
}
@media (min-width: 768px) {
  .main-video-desc h3 {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .main-video-desc h3 {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .main-video-form-wrapper {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .main-video-form-wrapper {
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .main-video-form-wrapper {
    padding-left: 45px;
  }
}
.main-video-form-wrapper input {
  border: none;
  width: 100%;
}
@media (max-width: 767px) {
  .main-video-form-wrapper input {
    margin-bottom: 10px;
  }
}
.main-video-form-wrapper .btn {
  padding: 12px 30px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .main-video-form-wrapper .btn {
    width: 100%;
  }
}
.main__faq {
  display: block;
  background: url('../img/main/main__faq.png') #75c58e 50% bottom no-repeat;
  text-decoration: none;
  color: #fff;
  padding-top: 35px;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 35px;
}
.main__faq:hover,
.main__faq:active,
.main__faq:focus,
.main__faq:active:focus {
  text-decoration: none;
  color: #fff;
}
@media (max-width: 767px) {
  .main__faq {
    padding-bottom: 200px;
  }
}
@media (min-width: 768px) {
  .main__faq {
    background-size: contain;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main__faq {
    background: #75c58e;
  }
}
.main__faq__header {
  font-size: 80px;
  line-height: 1;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
}
.main__faq__text {
  font-size: 16px;
  line-height: 1.375;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-align: center;
}
.go-to-reviews {
  display: none;
}
.rate-capability {
  width: 100%;
  background: #f5f4f0;
  padding: 50px;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 23px;
  line-height: 0.95;
  color: #75c58e;
}
.sheet {
  display: block;
  width: 100%;
  padding: 0px 25px;
  text-align: center;
  text-decoration: none;
}
.sheet--termookna {
  background-image: linear-gradient(#F4BE74 0%, #EF9559 100%);
}
.sheet__title {
  font-size: 22px;
  line-height: 1;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #fff;
}
.sheet__title:hover,
.sheet__title:active,
.sheet__title:focus {
  text-decoration: none;
  color: #fff;
}
@media (min-width: 768px) and (max-width: 991px) {
  .col-sm--flex {
    display: flex !important;
  }
}
@media (min-width: 1200px) {
  .col-lg--flex {
    display: flex !important;
  }
}
.social {
  height: 245px;
  background-image: linear-gradient(#A3DAB8 0%, #87CC9D 100%);
  padding: 50px 20px;
  margin: -1px 0;
  text-align: center;
}
.social__title {
  font-size: 22px;
  line-height: 1;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}
.social__preview {
  font-size: 16px;
  line-height: 1.22;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #32373b;
  margin-bottom: 20px;
}
.social__link {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url('../img/main/soc_sprite.png');
  box-shadow: 0px 15px 30px 0px rgba(48, 58, 62, 0.22);
}
.social__link-instagram {
  margin-right: 14px;
  background-position: 0 0;
}
.social__link-facebook {
  background-position: -30px 0;
}
.advantage-table {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .advantage-table {
    display: flex;
    align-items: stretch;
  }
  .advantage-table__cell {
    display: flex;
    align-items: stretch;
    float: none;
  }
}
.advantage-table__cell:nth-child(4) {
  margin-bottom: 15px;
}
@media (max-width: 1199px) {
  .advantage-table__cell:nth-child(4) {
    display: none;
  }
}
.btn-review-all,
.btn--banners {
  margin-bottom: 60px;
}
.advantage {
  border: 1px solid #e1e1e1;
  padding: 25px;
  text-align: center;
  -webkit-box-sizing: content-box;
  display: block;
  text-decoration: none;
  color: #32373b;
}
.advantage:hover,
.advantage:active,
.advantage:focus,
.advantage:active:focus {
  text-decoration: none;
  color: #32373b;
  border: 1px solid #f6cf45;
}
@media (max-width: 767px) {
  .advantage {
    padding: 25px;
  }
}
@media (min-width: 768px) {
  .advantage {
    padding: 30px 25px;
  }
}
@media (min-width: 992px) {
  .advantage {
    padding: 40px;
  }
}
.advantage__img {
  height: 115px;
  display: flex;
}
.advantage__img img {
  margin: auto;
}
@media (max-width: 991px) {
  .advantage__img {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .advantage__img {
    margin-bottom: 10px;
  }
}
.advantage__title {
  font-size: 22px;
  line-height: 1;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #f6cf45;
  margin-bottom: 10px;
}
.advantage__text {
  margin-bottom: 15px;
  padding: 0 25px;
}
.advantage__text,
.advantage__link {
  text-align: center;
  font-size: 16px;
  line-height: 1.375;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.advantage__link {
  color: #456e9b;
  text-decoration: underline;
}
.advantage__link:hover {
  text-decoration: none;
}
.news__header {
  margin-bottom: 20px;
}
.news__header__img {
  display: inline-block;
  vertical-align: baseline;
  margin-right: 15px;
}
.news__header__title {
  display: inline-block;
  margin-right: 25px;
  color: #32373b;
}
.news__header__links {
  display: inline-block;
  margin-bottom: 10px;
}
.news__header__link {
  font-size: 16px;
  line-height: 1.4;
  color: #a3a3a3;
  text-decoration: none;
}
.news__header__link:not(:last-child):after {
  content: ' / ';
}
.news__header__link.active span {
  color: #a3a3a3;
  text-decoration: none;
}
.news__header__link.active span:hover,
.news__header__link.active span:focus {
  cursor: default;
}
.news__header__link span {
  color: #456e9b;
  text-decoration: underline;
}
.news__header__link span:hover,
.news__header__link span:focus {
  text-decoration: none;
  cursor: pointer;
}
@media (min-width: 768px) and (max-width: 991px) {
  .banners__inner {
    margin: 0;
  }
}
.banner {
  display: block;
}
@media (min-width: 768px) and (max-width: 991px) {
  .banner {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .banner {
    margin-bottom: 15px;
  }
}
.btn--banners {
  margin-top: 30px;
}
